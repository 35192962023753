import { ref, Ref, unref } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { GqlTimeSeries, MaybeRef } from "@/types";
import { DateTime } from "luxon";

export interface UseOccupancyTrendResult {
  inSeries: Ref<GqlTimeSeries | null>;
  outSeries: Ref<GqlTimeSeries | null>;
  loadingError: Ref<boolean>;
  loading: Ref<boolean>;
  loadOccupancyTrend: () => void;
}

export function useOccupancyTrend(
  deviceUuid: MaybeRef<string>,
  startDate: MaybeRef<DateTime>,
  endDate: MaybeRef<DateTime>,
  period: MaybeRef<"daily" | "hourly">
): UseOccupancyTrendResult {
  const inSeries: Ref<GqlTimeSeries | null> = ref(null);
  const outSeries: Ref<GqlTimeSeries | null> = ref(null);
  const loadingError = ref(false);
  const periodSeconds = period === "daily" ? 86400 : 3600;

  const { onResult, onError, load, refetch, loading } = useLazyQuery(
    gql`
      query AccumulatedPropertyTrend($deviceUuids: [ID!]!, $startDate: DateTime!, $endDate: DateTime!, $period: Int!) {
        occupancyTrend: accumulatedPropertyTrend(
          deviceUuids: $deviceUuids
          filter: { property: "occupancy", startDate: $startDate, endDate: $endDate, period: $period }
        )
      }
    `,
    {
      deviceUuids: [unref(deviceUuid)],
      startDate: unref(startDate).setZone("utc"),
      endDate: unref(endDate).setZone("utc"),
      period: periodSeconds
    }
  );

  onError(() => {
    loadingError.value = true;
  });
  onResult(queryResult => {
    if (queryResult.data) {
      inSeries.value = queryResult.data.occupancyTrend.occ_total_in;
      outSeries.value = queryResult.data.occupancyTrend.occ_total_out;
    }
  });

  const loadOccupancyTrend = (): Promise<any> | undefined => {
    const args = {
      deviceUuids: [unref(deviceUuid)],
      startDate: unref(startDate).setZone("utc"),
      endDate: unref(endDate).setZone("utc"),
      period: periodSeconds
    };
    return load(null, args) || refetch(args);
  };

  return { inSeries, outSeries, loadingError, loading, loadOccupancyTrend };
}
